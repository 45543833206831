.main {
  margin-top: 1rem;
  .copyToClipboardBtn {
    margin: 0.3rem 0 1rem 0;
    display: flex;
    column-gap: 1rem;
    align-items: baseline;
  }
  .mask {
    word-break: break-all;
  }

  .radioGroupTop {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 1rem;
    margin-bottom: 2rem;
  }
}



.itemRadioGroup {
  display: flex;
  column-gap: 1rem;
  margin: 0.3rem 0;
}

.strSeparator {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img {
  height: 40px;
  width: auto;
}

.inputSeparator {
  input {
    width: 100px;
  }
}

