.main {
  h5 {
    margin: 45px 0 -35px 0;
  }
  .table {
    pre {
      margin: 0;
    }
    td {
      vertical-align: top;
    }
  }
}