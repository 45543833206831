.main{
  margin: 50px 25px;
  h5 {
    margin-bottom: 25px;
  }
  .video {
    position:relative;
    padding-bottom: 56.25%;
    margin-top: 50px;
    iframe {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;

    }
  } 
}
