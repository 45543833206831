.main {
  position: relative !important;
}

.markSection, .inputTextarea {
  font: 1rem Arial !important;
  white-space: pre-wrap !important;
  overflow-wrap: break-word !important;
  width: 100% !important;
}

.markSection {
  position: absolute !important;
  padding: 4px !important;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
  color: rgba(0,0,0,0);
  mark {
    color: rgba(0,0,0,0);
    border-radius: 2px;
    &:hover {
      cursor: pointer;
    }
  }
}

.inputTextarea {
  padding: 3px !important;
  resize: none !important;
  z-index: 11;
  background-color: rgba(0,0,0,0);
}
