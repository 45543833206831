.main {
  max-width: 1000px;
  margin: 0 auto;
  min-height: calc(100vh - 310px);
}

@media screen and (max-width: 650px) {
  .main {
    min-height: calc(100vh - 410px);
  }
}
