.main{
  margin: 50px 25px;
  h5 {
    margin-bottom: 25px;
  }
  .copyToClipboardBtn {
    margin: 20px 0;
  }
  .radioGroup {
    margin-bottom: 17px;
  }
  p {
    margin: 10px 0;
  }
  .controlSection {
    button {
      margin: 5px 15px 0 0;
    }

  }
}
.listFiles {
  display: grid;
  grid-template-columns: 250px 250px;
}

div[class*="MuiDropzoneArea-invalid"] {
  border-color: #7986cb;
  background-image: repeating-linear-gradient(-45deg, #fff, #fff 25px, rgba(0, 0, 0, 0.12) 25px, rgba(0, 0, 0, 0.12) 50px);
}
