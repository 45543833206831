.main {
  display: grid;
  grid-template-columns: 55% 44%;
  & > div {
    display: flex;
    margin-top: -15px;
  }
}

@media screen and (max-width: 1000px) {
  .main {
    display: grid;
    grid-template-columns: 1fr;
  }
}