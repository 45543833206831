.moboleMenu {
  display: none;
}

@media screen and (max-width: 650px) {
  .moboleMenu {
    display: block;
  }
  .desktopMenu {
    display: none;
  }
}
