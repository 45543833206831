footer {
    border-top: 1px solid #999;
    padding: 30px 0 0 0;
    margin-top: 70px;
    margin-bottom: 30px;
    .main {
        margin: 0 auto;
        max-width: 1000px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            margin: 0 7px;
        }
        a {
            color: inherit;
        }
        .walletIcon {
            position: relative;
            margin-right: 6px;
            top: 7px;
        }
        .social {
            a {
                margin: 1rem;
                img {
                    width: auto;
                    height: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .main {
        flex-direction: column;
        row-gap: 1rem;
    }
}
